<template>
  <div class="form-group">
    <label class="form-label text-muted"> {{ label }} </label>
    <input
      v-if="readOnly"
      class="form-control form-disabled"
      :value="value"
      disabled
    />
    <input
      v-else
      class="form-control"
      :value="model.$model"
      
      :class="{ 'is-invalid': model.$error }"
      :type="type"
      :placeholder="placeholder"
    />
    <div class="invalid-feedback">
      <small>{{ errorMsg }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    model: {
      required: false,
      default: () => {},
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "Field name",
    },
    placeholder: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "Por favor, preencha este campo corretamente",
    },
    value: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
