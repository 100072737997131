<template>
  <div>
    <AppContainer col="col-12 col-lg-5 col-xl-5">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row">
            <div class="col-12">
              <img
                src="@/assets/img/logo.png"
                alt=""
                class="mb-4 rounded"
                :style="'height: 100px; width:100px;'"
              />
            </div>
          </div>
          <div class="row" v-if="!success">
            <div class="col">
              <h6 class="header-pretitle">{{texts.title}}</h6>
              <h1 class="header-title">{{texts.subtitle}}</h1>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!success">
        <PasswordInput
          :label="texts.password.label"
          :model="$v.password"
          class="text-white"
          :errorMsg="texts.password.errorMsg"
          :placeholder="texts.password.placeholder"
        />
        <PasswordInput
          :label="texts.confirmPassword.label"
          :model="$v.passwordConfirmation"
          class="text-white"
          :errorMsg="texts.confirmPassword.errorMsg"
          :placeholder="texts.confirmPassword.placeholder"
        />
        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>
        <div class="d-grid gap-2">
          <FormButton
            :label="texts.buttonTitle"
            :loading="loading"
            :disabled="$v.$invalid"
            @onClick="changePassword"
          />
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-12">
            <span class="fas fa-3x text-success fa-check-circle"></span>
            <h1 class="text-success mt-3 mb-0"> {{texts.successTitle}}</h1>
            <span class="text-muted">{{texts.successSubtitle}}</span>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import PasswordResetController from "./PasswordResetController";
export default PasswordResetController;
</script>

<style scoped>
.main-content {
  margin-left: 0;
}
</style>
