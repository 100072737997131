// import Sidebar from '../../components/Sidebar.vue'
// import NavBar from '../../components/NavBar.vue'
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
    loadingAll: false,
    name: 'PasswordReset',
    mounted() {
        
    },
    data() {
        return {
            token: this.$route?.query.token,
            email: this.$route?.query.email,
            env: this.$route?.query.env,
            password: '',
            passwordConfirmation: '',
            errorMessage: '',
            loading: false,
            success: false,



            texts: {
                title: 'Password Recovery',
                subtitle: 'Please, type the new password',

                password: {
                    label: 'Password',
                    placeholder: 'Type the new password',
                    errorMsg: 'Password must have at least 8 characters'

                },
                confirmPassword: {
                    label: 'Confirm password',
                    placeholder: 'Type the password again',
                    errorMsg: "Passwords don't match"
                },

                buttonTitle: 'Update',
                successTitle: 'Your password has been reset successfully!',
                successSubtitle: 'You can access the app now'

            }
        }
    },
    validations: {
        password: { required, minLength: minLength(8) },
        passwordConfirmation: { required, sameAsPassword: sameAs('password') },
    },
    methods: {
        async changePassword() {
            try {
                this.loading = true
                this.errorMessage = ''
                const params = {
                    email: this.email,
                    password: this.password,
                    token: this.token
                }
                const res = await this.$userService.resetPassword(params, this.env)
                this.success = res.data.status || false
            
                if (!res.data.status) {
                    this.errorMessage = res.data.message    
                }
            } catch (e) {
                this.errorMessage = e.message
            }
            finally {
                this.loading = false
            }
        }
    },
    components: {
    }
}