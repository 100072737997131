import axios from 'axios'

export default class UserService {

    baseUrl = {
        prod: 'https://apiv2.devotee.com.br/',
        dev: 'https://dev-apiv2.devotee.com.br/',
    }
    endpoint = 'api/password/reset'
    resetPassword(params, environment = 'prod') {
        const url = `${this.baseUrl[environment]}${this.endpoint}`
        return axios.post(url, params)
    }
}

