import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap'
import 'jquery'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './assets/theme/scss/theme.scss'
import './styles.scss'
import TextInput from "@/components/FormComponents/TextInput.vue";
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import AppContainer from "@/components/AppContainer.vue";
import PasswordReset from "@/views/PasswordReset/PasswordReset.vue";
import FormButton from "@/components/FormComponents/FormButton.vue";
import UserService from '@/services/UserService'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
Vue.prototype.$userService = new UserService()
Vue.component('TextInput', TextInput)
Vue.component('PasswordInput', PasswordInput)
Vue.component('FormButton', FormButton)
Vue.component('AppContainer', AppContainer)
Vue.use(Vuelidate)

Vue.config.productionTip = false


const router = new VueRouter({
  mode: 'history'
  // base: process.env.BASE_URL,
  // routes,
  // paths
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
